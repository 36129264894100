#repaymentWidget .widgetToolContainer {
    width: 330px;
    padding: 20px 0px 10px 15px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    font-family: "Open Sans";
    border-radius: 20px;
}

#repaymentWidget .widgetTool {
    font-family: "Open Sans";
    color: var(--widgetTertiary);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}

#repaymentWidget .widgetNumber {
    float: left;
}

#repaymentWidget .widgetButtonGroup {
    display: flex;
    flex-wrap: wrap;
}

#repaymentWidget .widgetButton {
    width: 140px;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 1px 1px 1px #d4d4d4;
    color: var(--widgetSecondary);
    margin: 5px 5px;
    border-radius: 50px;
    font-size: 14px;
    line-height: 35px;
    font-weight: 800;
    cursor: pointer;
    border: none;
    letter-spacing: 0.2px;
    font-family: inherit;
    padding: 5px;
}

#repaymentWidget .widgetButtonActive {
    background-color: var(--widgetTertiary);
    border-color: var(--widgetTertiary);
    color: #ffffff;
}

#repaymentWidget #widgetSlider {
    width: 85%;
    min-width: none;
    margin: 0 auto;
}

#repaymentWidget #widgetSlider {
    display: grid;
    grid-template-columns: 15px auto 15px;
}

#repaymentWidget #widgetSlider span {
    margin: 0 auto;
    font-family: "Open Sans";
    font-weight: 900;
    color: var(--widgetSecondary);
}

#repaymentWidget #widgetSlider .rc-slider {
    margin: auto 0;
}

#repaymentWidget #widgetSlider .rc-slider-track {
    background-color: var(--widgetSecondary);
}

#repaymentWidget #widgetSlider .rc-slider-handle {
    background-color: var(--widgetSecondary);
    border: solid 2px var(--widgetSecondary);
    width: 32px;
    height: 32px;
    margin-top: -15px;
    opacity: 1;
}

#repaymentWidget #widgetSlider .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
}

#repaymentWidget #widgetSlider .rc-slider-dot {
    border: none;
    background-color: transparent;
}

#repaymentWidget #widgetSlider .rc-slider-dot-active {
    border-color: transparent;
}

#repaymentWidget #widgetSlider .rc-slider-rail {
    background-color: #c4c4c4;
    height: 5px;
}

#repaymentWidget #widgetSlider .rc-slider-mark {
    font-size: 100%;
    font-weight: bold;
    top: -6px;
    pointer-events: none;
}

#repaymentWidget #widgetSlider .rc-slider-mark span {
    color: white;
}

#repaymentWidget #loanAmount {
    position: relative;
}

#repaymentWidget #loanAmountInput {
    width: 250px;
    color: var(--widgetSecondary);
    border-bottom: 1px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.3px;
    padding: 1px 2px;
    margin: 0px;
    display: inline;
}

#repaymentWidget #loanAmountInput:focus {
    outline: none;
    box-shadow: none;
}

#repaymentWidget #loanAmountInput::placeholder {
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    color: var(--widgetSecondary);
}

#repaymentWidget #loanAmountSign {
    position: absolute;
    color: var(--widgetSecondary);
    font-size: 20px;
    font-weight: bold;
    bottom: 5%;
}

#repaymentWidget .widgetProceedButton {
    border-radius: 50px;
    box-shadow: 0 4px 6px #888;
    width: 250px;
    color: #fff;
    background-color: var(--widgetPrimary);
    border: none;
    height: 40px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0.5px;
}

#repaymentWidget .widgetProceedButton.disabled {
    pointer-events: none;
    opacity: 0.65;
}

#repaymentWidget .widgetProceedButton.focus {
    border: none;
}

#repaymentWidget #creditScoreText {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 11px;
    color: black;
}

#repaymentWidget .toolTipText {
    font-size: 10px;
    color: red;
    font-style: italic;
}

#repaymentWidget p {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
